/*
- todo {
- loading overlay colors {
- rich select colors {
 */
.ag-icon:not(.ag-faded) {
  opacity: 0.8; }

.ag-fresh {
  line-height: 1.4;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #222;
  /* this is for the rowGroupPanel, that appears along the top of the grid */
  /* this is for the column drops that appear in the toolPanel */ }
  .ag-fresh .ag-numeric-cell {
    text-align: right; }
  .ag-fresh .ag-header-cell-label {
    display: flex; }
    .ag-fresh .ag-header-cell-label > span {
      float: left; }
    .ag-fresh .ag-header-cell-label .ag-header-icon {
      margin-top: 2px; }
    .ag-fresh .ag-header-cell-label .ag-header-cell-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .ag-fresh .ag-numeric-header .ag-header-cell-label {
    flex-direction: row-reverse; }
  .ag-fresh .ag-numeric-header .ag-header-cell-menu-button {
    float: left; }
  .ag-fresh .ag-numeric-header .ag-header-cell-label {
    width: calc(100% - 12px);
    float: right; }
    .ag-fresh .ag-numeric-header .ag-header-cell-label > span {
      float: right; }
  .ag-fresh .ag-header-cell-resize {
    position: absolute;
    right: 0; }
  .ag-fresh .ag-rtl .ag-header-cell-resize {
    position: absolute;
    left: 0;
    right: auto; }
  .ag-fresh img {
    vertical-align: middle;
    border: 0; }
  .ag-fresh .ag-root {
    border: 1px solid darkgrey; }
  .ag-fresh .ag-cell-data-changed {
    background-color: #cec; }
  .ag-fresh .ag-cell-data-changed-animation {
    background-color: transparent;
    transition: background-color 1s; }
  .ag-fresh .ag-cell-not-inline-editing {
    padding: 2px;
    /* compensate for the transparent borders; */
    padding-left: 1px; }
  .ag-fresh .ag-cell-range-selected-1:not(.ag-cell-focus) {
    background-color: rgba(120, 120, 120, 0.4); }
  .ag-fresh .ag-cell-range-selected-2:not(.ag-cell-focus) {
    background-color: rgba(80, 80, 80, 0.4); }
  .ag-fresh .ag-cell-range-selected-3:not(.ag-cell-focus) {
    background-color: rgba(40, 40, 40, 0.4); }
  .ag-fresh .ag-cell-range-selected-4:not(.ag-cell-focus) {
    background-color: rgba(0, 0, 0, 0.4); }
  .ag-fresh .ag-cell-focus {
    border: 1px solid darkgrey; }
  .ag-fresh .ag-cell-no-focus {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent; }
  .ag-fresh .ag-ltr .ag-cell-no-focus {
    border-right: 1px dotted silver;
    border-left: 1px solid transparent; }
  .ag-fresh .ag-rtl .ag-cell-no-focus {
    border-right: 1px solid transparent;
    border-left: 1px dotted silver; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-1 {
    padding-left: 10px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-1 {
    padding-right: 10px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-1 {
    padding-left: 10px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-1 {
    padding-right: 10px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-2 {
    padding-left: 20px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-2 {
    padding-right: 20px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-2 {
    padding-left: 20px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-2 {
    padding-right: 20px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-3 {
    padding-left: 30px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-3 {
    padding-right: 30px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-3 {
    padding-left: 30px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-3 {
    padding-right: 30px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-4 {
    padding-left: 40px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-4 {
    padding-right: 40px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-4 {
    padding-left: 40px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-4 {
    padding-right: 40px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-5 {
    padding-left: 50px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-5 {
    padding-right: 50px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-5 {
    padding-left: 50px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-5 {
    padding-right: 50px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-6 {
    padding-left: 60px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-6 {
    padding-right: 60px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-6 {
    padding-left: 60px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-6 {
    padding-right: 60px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-7 {
    padding-left: 70px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-7 {
    padding-right: 70px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-7 {
    padding-left: 70px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-7 {
    padding-right: 70px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-8 {
    padding-left: 80px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-8 {
    padding-right: 80px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-8 {
    padding-left: 80px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-8 {
    padding-right: 80px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-9 {
    padding-left: 90px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-9 {
    padding-right: 90px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-9 {
    padding-left: 90px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-9 {
    padding-right: 90px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-10 {
    padding-left: 100px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-10 {
    padding-right: 100px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-10 {
    padding-left: 100px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-10 {
    padding-right: 100px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-11 {
    padding-left: 110px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-11 {
    padding-right: 110px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-11 {
    padding-left: 110px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-11 {
    padding-right: 110px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-12 {
    padding-left: 120px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-12 {
    padding-right: 120px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-12 {
    padding-left: 120px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-12 {
    padding-right: 120px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-13 {
    padding-left: 130px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-13 {
    padding-right: 130px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-13 {
    padding-left: 130px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-13 {
    padding-right: 130px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-14 {
    padding-left: 140px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-14 {
    padding-right: 140px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-14 {
    padding-left: 140px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-14 {
    padding-right: 140px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-15 {
    padding-left: 150px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-15 {
    padding-right: 150px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-15 {
    padding-left: 150px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-15 {
    padding-right: 150px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-16 {
    padding-left: 160px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-16 {
    padding-right: 160px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-16 {
    padding-left: 160px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-16 {
    padding-right: 160px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-17 {
    padding-left: 170px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-17 {
    padding-right: 170px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-17 {
    padding-left: 170px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-17 {
    padding-right: 170px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-18 {
    padding-left: 180px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-18 {
    padding-right: 180px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-18 {
    padding-left: 180px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-18 {
    padding-right: 180px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-19 {
    padding-left: 190px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-19 {
    padding-right: 190px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-19 {
    padding-left: 190px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-19 {
    padding-right: 190px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-20 {
    padding-left: 200px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-20 {
    padding-right: 200px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-20 {
    padding-left: 200px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-20 {
    padding-right: 200px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-21 {
    padding-left: 210px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-21 {
    padding-right: 210px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-21 {
    padding-left: 210px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-21 {
    padding-right: 210px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-22 {
    padding-left: 220px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-22 {
    padding-right: 220px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-22 {
    padding-left: 220px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-22 {
    padding-right: 220px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-23 {
    padding-left: 230px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-23 {
    padding-right: 230px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-23 {
    padding-left: 230px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-23 {
    padding-right: 230px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-24 {
    padding-left: 240px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-24 {
    padding-right: 240px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-24 {
    padding-left: 240px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-24 {
    padding-right: 240px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-25 {
    padding-left: 250px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-25 {
    padding-right: 250px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-25 {
    padding-left: 250px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-25 {
    padding-right: 250px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-26 {
    padding-left: 260px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-26 {
    padding-right: 260px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-26 {
    padding-left: 260px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-26 {
    padding-right: 260px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-27 {
    padding-left: 270px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-27 {
    padding-right: 270px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-27 {
    padding-left: 270px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-27 {
    padding-right: 270px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-28 {
    padding-left: 280px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-28 {
    padding-right: 280px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-28 {
    padding-left: 280px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-28 {
    padding-right: 280px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-29 {
    padding-left: 290px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-29 {
    padding-right: 290px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-29 {
    padding-left: 290px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-29 {
    padding-right: 290px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-30 {
    padding-left: 300px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-30 {
    padding-right: 300px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-30 {
    padding-left: 300px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-30 {
    padding-right: 300px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-31 {
    padding-left: 310px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-31 {
    padding-right: 310px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-31 {
    padding-left: 310px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-31 {
    padding-right: 310px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-32 {
    padding-left: 320px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-32 {
    padding-right: 320px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-32 {
    padding-left: 320px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-32 {
    padding-right: 320px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-33 {
    padding-left: 330px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-33 {
    padding-right: 330px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-33 {
    padding-left: 330px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-33 {
    padding-right: 330px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-34 {
    padding-left: 340px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-34 {
    padding-right: 340px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-34 {
    padding-left: 340px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-34 {
    padding-right: 340px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-35 {
    padding-left: 350px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-35 {
    padding-right: 350px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-35 {
    padding-left: 350px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-35 {
    padding-right: 350px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-36 {
    padding-left: 360px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-36 {
    padding-right: 360px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-36 {
    padding-left: 360px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-36 {
    padding-right: 360px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-37 {
    padding-left: 370px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-37 {
    padding-right: 370px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-37 {
    padding-left: 370px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-37 {
    padding-right: 370px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-38 {
    padding-left: 380px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-38 {
    padding-right: 380px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-38 {
    padding-left: 380px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-38 {
    padding-right: 380px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-39 {
    padding-left: 390px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-39 {
    padding-right: 390px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-39 {
    padding-left: 390px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-39 {
    padding-right: 390px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-40 {
    padding-left: 400px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-40 {
    padding-right: 400px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-40 {
    padding-left: 400px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-40 {
    padding-right: 400px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-41 {
    padding-left: 410px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-41 {
    padding-right: 410px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-41 {
    padding-left: 410px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-41 {
    padding-right: 410px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-42 {
    padding-left: 420px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-42 {
    padding-right: 420px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-42 {
    padding-left: 420px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-42 {
    padding-right: 420px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-43 {
    padding-left: 430px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-43 {
    padding-right: 430px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-43 {
    padding-left: 430px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-43 {
    padding-right: 430px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-44 {
    padding-left: 440px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-44 {
    padding-right: 440px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-44 {
    padding-left: 440px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-44 {
    padding-right: 440px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-45 {
    padding-left: 450px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-45 {
    padding-right: 450px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-45 {
    padding-left: 450px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-45 {
    padding-right: 450px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-46 {
    padding-left: 460px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-46 {
    padding-right: 460px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-46 {
    padding-left: 460px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-46 {
    padding-right: 460px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-47 {
    padding-left: 470px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-47 {
    padding-right: 470px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-47 {
    padding-left: 470px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-47 {
    padding-right: 470px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-48 {
    padding-left: 480px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-48 {
    padding-right: 480px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-48 {
    padding-left: 480px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-48 {
    padding-right: 480px; }
  .ag-fresh .ag-ltr .ag-toolpanel-indent-49 {
    padding-left: 490px; }
  .ag-fresh .ag-rtl .ag-toolpanel-indent-49 {
    padding-right: 490px; }
  .ag-fresh .ag-ltr .ag-row-group-indent-49 {
    padding-left: 490px; }
  .ag-fresh .ag-rtl .ag-row-group-indent-49 {
    padding-right: 490px; }
  .ag-fresh .ag-rtl .ag-cell-first-right-pinned {
    border-left: 1px solid darkgrey; }
  .ag-fresh .ag-ltr .ag-cell-first-right-pinned {
    border-left: 1px solid darkgrey; }
  .ag-fresh .ag-rtl .ag-cell-last-left-pinned {
    border-right: 1px solid darkgrey; }
  .ag-fresh .ag-ltr .ag-cell-last-left-pinned {
    border-right: 1px solid darkgrey; }
  .ag-fresh .ag-cell-highlight {
    border: 1px solid darkgreen; }
  .ag-fresh .ag-cell-highlight-animation {
    transition: border 1s; }
  .ag-fresh .ag-value-change-delta {
    padding-right: 2px; }
  .ag-fresh .ag-value-change-delta-up {
    color: darkgreen; }
  .ag-fresh .ag-value-change-delta-down {
    color: darkred; }
  .ag-fresh .ag-value-change-value {
    background-color: transparent;
    border-radius: 1px;
    padding-left: 1px;
    padding-right: 1px;
    transition: background-color 1s; }
  .ag-fresh .ag-value-change-value-highlight {
    background-color: #cec;
    transition: background-color 0.1s; }
  .ag-fresh .ag-rich-select {
    font-size: 14px;
    border: 1px solid darkgrey;
    background-color: white; }
  .ag-fresh .ag-rich-select-value {
    padding: 2px; }
  .ag-fresh .ag-rich-select-list {
    border-top: 1px solid #d3d3d3; }
  .ag-fresh .ag-rich-select-row {
    padding: 2px; }
  .ag-fresh .ag-rich-select-row-selected {
    background-color: #BDE2E5; }
  .ag-fresh .ag-large-text {
    border: 1px solid darkgrey; }
  .ag-fresh .ag-header-select-all, .ag-fresh .ag-header-cell-menu-button {
    margin-top: 3px;
    line-height: 1rem; }
  .ag-fresh .ag-header-select-all {
    padding-right: 4px; }
  .ag-fresh .ag-filter-header-container > label {
    margin-bottom: 0; }
  .ag-fresh .ag-header-cell {
    padding: 2px;
    padding-top: 4px; }
  .ag-fresh .ag-header {
    color: #333;
    background: linear-gradient(white, lightgrey);
    border-bottom: 1px solid darkgrey;
    font-weight: normal; }
  .ag-fresh .ag-header-icon {
    color: #333;
    stroke: none;
    fill: #333; }
  .ag-fresh .ag-filter-icon {
    display: inline-block; }
  .ag-fresh .ag-sort-ascending-icon:empty {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik01IDJoMnY5SDV6Ii8+PHBhdGggZD0iTTguOTkzIDYuMVY0LjM5M2gtNnY2SDQuN1Y2LjFoNC4yOTN6IiBpZD0iYiIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2EiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik01LjUgMi41aDF2OGgtMXoiLz48ZyB0cmFuc2Zvcm09InJvdGF0ZSgtMTM1IDUuOTkzIDcuMzkzKSI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjYiIvPjxwYXRoIHN0cm9rZT0iIzAwMCIgZD0iTTguNDkzIDUuNnYtLjcwN2gtNXY1SDQuMlY1LjZoNC4yOTN6Ii8+PC9nPjwvZz48L3N2Zz4=) center no-repeat;
    background-size: 12px 12px;
    filter: "initial"; }
  .ag-fresh .ag-sort-descending-icon:empty {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik01IDNoMnY5SDV6Ii8+PHBhdGggZD0iTTguOTkzIDUuMlYzLjQ5M2gtNnY2SDQuN1Y1LjJoNC4yOTN6IiBpZD0iYiIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2EiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik01LjUgMy41aDF2OGgtMXoiLz48ZyB0cmFuc2Zvcm09InJvdGF0ZSg0NSA1Ljk5MyA2LjQ5MykiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2IiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik04LjQ5MyA0Ljd2LS43MDdoLTV2NUg0LjJWNC43aDQuMjkzeiIvPjwvZz48L2c+PC9zdmc+) center no-repeat;
    background-size: 12px 12px;
    filter: "initial"; }
  .ag-fresh .ag-sort-none-icon:empty {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik01IDNoMnY2SDV6Ii8+PHBhdGggZD0iTTguMTQ2IDguMTgyVjYuNDc1aC01djVoMS43MDhWOC4xODJoMy4yOTJ6IiBpZD0iYiIvPjxwYXRoIGQ9Ik04LjUgMi45MTRWMS4yMDdoLTV2NWgxLjcwN1YyLjkxNEg4LjV6IiBpZD0iYyIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2EiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik01LjUgMy41aDF2NWgtMXoiLz48ZyB0cmFuc2Zvcm09InJvdGF0ZSgtMTM1IDUuNjQ2IDguNDc1KSI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjYiIvPjxwYXRoIHN0cm9rZT0iIzAwMCIgZD0iTTcuNjQ2IDcuNjgydi0uNzA3aC00djRoLjcwOFY3LjY4MmgzLjI5MnoiLz48L2c+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNiAzLjcwNykiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2MiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik04IDIuNDE0di0uNzA3SDR2NGguNzA3VjIuNDE0SDh6Ii8+PC9nPjwvZz48L3N2Zz4=) center no-repeat;
    background-size: 12px 12px;
    filter: "initial"; }
  .ag-fresh .ag-layout-for-print .ag-header-container {
    background: linear-gradient(white, lightgrey);
    border-bottom: 1px solid darkgrey; }
  .ag-fresh .ag-ltr .ag-header-cell {
    border-right: 1px solid darkgrey; }
  .ag-fresh .ag-rtl .ag-header-cell {
    border-left: 1px solid darkgrey; }
  .ag-fresh .ag-header-cell-moving .ag-header-cell-label {
    opacity: 0.5; }
  .ag-fresh .ag-header-cell-moving {
    background-color: #bebebe; }
  .ag-fresh .ag-ltr .ag-header-group-cell {
    border-right: 1px solid darkgrey; }
  .ag-fresh .ag-rtl .ag-header-group-cell {
    border-left: 1px solid darkgrey; }
  .ag-fresh .ag-header-group-cell-with-group {
    border-bottom: 1px solid darkgrey; }
  .ag-fresh .ag-header-group-cell-label {
    padding: 2px;
    padding-top: 4px; }
  .ag-fresh .ag-rtl .ag-header-group-text {
    margin-left: 2px; }
  .ag-fresh .ag-ltr .ag-header-group-text {
    margin-right: 2px; }
  .ag-fresh .ag-header-cell-menu-button:empty {
    width: 12px;
    height: 12px;
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxaDEwdjJIMXptMCA0aDEwdjJIMXptMCA0aDEwdjJIMXoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==) center no-repeat;
    background-size: 12px 12px;
    filter: "initial"; }
  .ag-fresh .ag-ltr .ag-pinned-right-header {
    border-left: 1px solid darkgrey; }
  .ag-fresh .ag-rtl .ag-pinned-left-header {
    border-right: 1px solid darkgrey; }
  .ag-fresh .ag-body {
    background-color: #f6f6f6; }
  .ag-fresh .ag-row-odd {
    background-color: #f6f6f6; }
  .ag-fresh .ag-row-even {
    background-color: white; }
  .ag-fresh .ag-row-selected {
    background-color: powderblue; }
  .ag-fresh .ag-row-stub {
    background-color: #f0f0f0; }
  .ag-fresh .ag-stub-cell {
    padding: 2px 2px 2px 10px; }
  .ag-fresh .ag-floating-top {
    background-color: #f0f0f0; }
  .ag-fresh .ag-floating-top .ag-row {
    background-color: #f0f0f0; }
  .ag-fresh .ag-floating-bottom {
    background-color: #f0f0f0; }
  .ag-fresh .ag-floating-bottom .ag-row {
    background-color: #f0f0f0; }
  .ag-fresh .ag-overlay-loading-wrapper {
    background-color: rgba(255, 255, 255, 0.5); }
  .ag-fresh .ag-overlay-loading-center {
    background-color: #ffffff;
    border: 1px solid darkgrey;
    border-radius: 10px;
    padding: 10px;
    color: black; }
  .ag-fresh .ag-overlay-no-rows-center {
    background-color: #ffffff;
    border: 1px solid darkgrey;
    border-radius: 10px;
    padding: 10px; }
  .ag-fresh .ag-group-cell-entire-row {
    background-color: #f6f6f6;
    padding: 2px; }
  .ag-fresh .ag-footer-cell-entire-row {
    background-color: #f6f6f6;
    padding: 2px; }
  .ag-fresh .ag-group-cell {
    font-style: italic; }
  .ag-fresh .ag-ltr .ag-group-expanded {
    padding-right: 4px; }
  .ag-fresh .ag-rtl .ag-group-expanded {
    padding-left: 4px; }
  .ag-fresh .ag-ltr .ag-group-contracted {
    padding-right: 4px; }
  .ag-fresh .ag-rtl .ag-group-contracted {
    padding-left: 4px; }
  .ag-fresh .ag-ltr .ag-group-loading {
    padding-right: 4px; }
  .ag-fresh .ag-rtl .ag-group-loading {
    padding-left: 4px; }
  .ag-fresh .ag-ltr .ag-group-value {
    padding-right: 2px; }
  .ag-fresh .ag-rtl .ag-group-value {
    padding-left: 2px; }
  .ag-fresh .ag-ltr .ag-group-checkbox {
    padding-right: 2px; }
  .ag-fresh .ag-rtl .ag-group-checkbox {
    padding-left: 2px; }
  .ag-fresh .ag-group-child-count {
    /* display: inline-block; */ }
  .ag-fresh .ag-footer-cell {
    font-style: italic; }
  .ag-fresh .ag-menu {
    border: 1px solid #808080;
    background-color: #f6f6f6;
    cursor: default;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px; }
    .ag-fresh .ag-menu .ag-tab-header {
      background-color: #e6e6e6; }
    .ag-fresh .ag-menu .ag-tab {
      padding: 6px 8px 6px 8px;
      margin: 2px 2px 0px 2px;
      display: inline-block;
      border-right: 1px solid transparent;
      border-left: 1px solid transparent;
      border-top: 1px solid transparent;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px; }
    .ag-fresh .ag-menu .ag-tab-selected {
      background-color: #f6f6f6;
      border-right: 1px solid #d3d3d3;
      border-left: 1px solid #d3d3d3;
      border-top: 1px solid #d3d3d3; }
  .ag-fresh .ag-menu-separator {
    border-top: 1px solid #d3d3d3; }
  .ag-fresh .ag-menu-option-active {
    background-color: #BDE2E5; }
  .ag-fresh .ag-menu-option-icon {
    padding: 2px 4px 2px 4px;
    vertical-align: middle; }
  .ag-fresh .ag-menu-option-text {
    padding: 2px 4px 2px 4px;
    vertical-align: middle; }
  .ag-fresh .ag-menu-option-shortcut {
    padding: 2px 2px 2px 2px;
    vertical-align: middle; }
  .ag-fresh .ag-menu-option-popup-pointer {
    padding: 2px 4px 2px 4px;
    vertical-align: middle;
    display: table-cell; }
  .ag-fresh .ag-menu-option-disabled {
    opacity: 0.5; }
  .ag-fresh .ag-menu-column-select-wrapper {
    margin: 2px; }
  .ag-fresh .ag-filter-checkbox {
    margin-right: 4px;
    margin-bottom: 0;
    display: inline-block; }
  .ag-fresh .ag-filter-header-container {
    padding: 2px 4px 2px 4px;
    border-bottom: 1px solid #d3d3d3; }
  .ag-fresh .ag-filter-apply-panel {
    border-top: 1px solid #d3d3d3;
    padding: 2px 0px 2px 4px; }
  .ag-fresh .ag-virtual-list-container {
    padding: 4px 4px 10px 4px; }
  .ag-fresh .ag-ltr .ag-selection-checkbox {
    padding-right: 4px; }
  .ag-fresh .ag-rtl .ag-selection-checkbox {
    padding-left: 4px; }
  .ag-fresh .ag-paging-panel {
    padding: 4px; }
  .ag-fresh .ag-paging-button {
    margin-left: 4px;
    margin-right: 4px; }
  .ag-fresh .ag-paging-row-summary-panel {
    display: inline-block;
    width: 300px; }
  .ag-fresh .ag-column-panel {
    background-color: #f6f6f6;
    border-bottom: 1px solid darkgrey;
    border-top: 1px solid darkgrey;
    color: #222; }
  .ag-fresh .ltr .ag-column-panel {
    border-right: 1px solid darkgrey; }
  .ag-fresh .rtl .ag-column-panel {
    border-left: 1px solid darkgrey; }
  .ag-fresh .ag-status-bar {
    color: #222;
    background-color: #f6f6f6;
    font-size: 14px;
    height: 22px;
    border-bottom: 1px solid darkgrey;
    border-left: 1px solid darkgrey;
    border-right: 1px solid darkgrey;
    padding: 2px; }
  .ag-fresh .ag-status-bar-aggregations {
    float: right; }
  .ag-fresh .ag-status-bar-item {
    padding-left: 10px; }
  .ag-fresh .ag-column-drop-cell {
    background: linear-gradient(white, lightgrey);
    color: #000;
    border: 1px solid #808080; }
  .ag-fresh .ag-column-drop-cell-ghost {
    opacity: 0.5; }
  .ag-fresh .ag-column-drop-cell-text {
    padding-left: 2px;
    padding-right: 2px; }
  .ag-fresh .ag-column-drop-cell-button {
    border: 1px solid transparent;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 3px; }
  .ag-fresh .ag-column-drop-cell-button:hover {
    border: 1px solid darkgrey; }
  .ag-fresh .ag-column-drop-empty-message {
    padding-left: 2px;
    padding-right: 2px;
    color: grey; }
  .ag-fresh .ag-column-drop-icon {
    margin: 6px 3px 0px 3px; }
  .ag-fresh .ag-column-drop {
    background-color: #f6f6f6; }
  .ag-fresh .ag-column-drop-horizontal {
    padding: 2px;
    border-top: 1px solid darkgrey;
    border-left: 1px solid darkgrey;
    border-right: 1px solid darkgrey; }
  .ag-fresh .ag-column-drop-vertical {
    padding: 4px 4px 10px 4px;
    border-bottom: 1px solid darkgrey;
    overflow: auto; }
    .ag-fresh .ag-column-drop-vertical .ag-column-drop-cell {
      margin-top: 2px; }
    .ag-fresh .ag-column-drop-vertical .ag-column-drop-empty-message {
      padding: 5px; }
  .ag-fresh .ag-pivot-mode {
    border-bottom: 1px solid darkgrey;
    padding: 2px 4px 3px 4px;
    background-color: #f6f6f6; }
  .ag-fresh .ag-column-panel .ag-column-select-panel {
    padding: 4px 4px 10px 4px;
    padding-left: 0;
    border-bottom: 1px solid darkgrey;
    overflow: auto; }
  .ag-fresh .ag-select-agg-func-popup {
    cursor: default;
    position: absolute;
    font-size: 14px;
    background-color: white;
    border: 1px solid darkgrey; }
  .ag-fresh .ag-select-agg-func-item {
    padding-left: 2px;
    padding-right: 2px; }
  .ag-fresh .ag-select-agg-func-item:hover {
    background-color: #BDE2E5; }
  .ag-fresh .ag-floating-filter-body {
    margin-right: 20px;
    width: calc(100% - 20px); }
  .ag-fresh .ag-floating-filter-button {
    margin-top: -20px;
    display: inline-block;
    float: right; }
    .ag-fresh .ag-floating-filter-button button {
      border: 0;
      background: transparent;
      padding: 3px;
      margin: 0; }
  .ag-fresh .ag-rtl .ag-floating-filter-body {
    margin-right: 0;
    margin-left: 20px;
    float: right; }
  .ag-fresh .ag-rtl .ag-floating-filter-button {
    float: left; }
  .ag-fresh .ag-sort-order {
    margin-left: .5em;
    font-size: 0.80em; }
    .ag-fresh .ag-sort-order::before {
      content: '('; }
    .ag-fresh .ag-sort-order::after {
      content: ')'; }
  .ag-fresh .ag-details-row {
    padding: 20px;
    box-sizing: border-box; }
